import { Box, Slide, Typography } from '@mui/material';
import ContactCard from './components/ContactCard';
import IntroCard from './components/IntroCard';
import SkillsCard from './components/SkillsCard';
import ExperienceCard from './components/ExperienceCard';
import AccomplishmentsCard from './components/AccomplishmentsCard';

const ResumePage = () => {
  return (
    <Box
      m={1}
      display={'flex'}
      flexWrap={'wrap'}
      flexBasis={'100%'}
      justifyContent='center'
    >
      <Box flexBasis={'100%'} m={1}>
        <Typography variant={'h5'} textAlign={'center'}>
          Resume
        </Typography>
      </Box>
      <Box
        display='flex'
        flexWrap='wrap'
        justifyContent={'center'}
        alignItems={'flex-start'}
        flexBasis={800}
      >
        <Box flexBasis={200} flexGrow={1} flexShrink={1} m={1}>
          <Box mb={1} flexBasis={'100%'}>
            <Slide timeout={600} direction='right' in={true} mountOnEnter unmountOnExit>
              <Box>
                <IntroCard />
              </Box>
            </Slide>
          </Box>
          <Box>
            <Slide timeout={800} direction='right' in={true} mountOnEnter unmountOnExit>
              <Box>
                <ExperienceCard />
              </Box>
            </Slide>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          m={1}
          flexBasis={250}
          flexGrow={0}
          flexShrink={0}
        >
          <Box mb={1} flexBasis={'100%'}>
            <Slide timeout={900} direction='left' in={true} mountOnEnter unmountOnExit>
              <Box>
                <ContactCard />
              </Box>
            </Slide>
          </Box>
          <Box flexBasis={'100%'}>
            <Slide timeout={1200} direction='left' in={true} mountOnEnter unmountOnExit>
              <Box>
                <AccomplishmentsCard />
              </Box>
            </Slide>
          </Box>
          <Box flexBasis={'100%'}>
            <Slide timeout={1500} direction='left' in={true} mountOnEnter unmountOnExit>
              <Box>
                <SkillsCard />
              </Box>
            </Slide>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResumePage;

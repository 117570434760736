import themeDark from '#resources/themes/themeDark';
import { Box, Paper, Typography } from '@mui/material';

const AccomplishmentsList = [
  'President of ACM Club',
  'Augusta University Chapter',
  'Captain of 3 Hackathon Teams',
  'Captain of 2 ACM Programming Competition (3 Teams per event)',
  'Coached American Heart Association Team ($5000 raised over 3 years',
  '3 Spot Awards (Workplace Recognitions)'
];

const AccomplishmentsCard = () => {
  return (
    <Box mb={1}>
      <Paper elevation={8} sx={{ background: themeDark.palette.Paper }}>
        <Box p={1}>
          <Box m={1} flexBasis={'100%'}>
            <Typography textAlign={'center'} variant='h6'>
              Accomplishments
            </Typography>
          </Box>
          <Box m={1}>
            {AccomplishmentsList &&
              AccomplishmentsList.map((item, index) => {
                return (
                  <Box key={index}>
                    <Typography key={index} variant='caption'>
                      &#x2022; {item}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AccomplishmentsCard;

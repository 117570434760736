import { Box, Typography, Slide } from '@mui/material';
import PokeyHelperCard from './components/PokeyHelperCard';

const ProjectsPage = () => {
  return (
    <Box m={1} display={'flex'} flexWrap={'wrap'} justifyContent='center'>
      <Box flexBasis={'100%'} m={2}>
        <Typography variant={'h5'} textAlign={'center'}>
          Projects
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexBasis={'100%'}
        flexWrap={'wrap'}
        justifyContent={'center'}
      >
        <Box flexBasis={600} minWidth={320}>
          <Slide timeout={900} direction='up' in={true} mountOnEnter unmountOnExit>
            <Box>
              <PokeyHelperCard />
            </Box>
          </Slide>
        </Box>
      </Box>
      <Box flexBasis={600}></Box>
    </Box>
  );
};

export default ProjectsPage;

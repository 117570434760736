import { Box, Paper, Typography } from '@mui/material';
import themeDark from '#resources/themes/themeDark';

const ContactCard = () => {
  return (
    <Box flexBasis={'100%'}>
      <Paper elevation={8} sx={{ background: themeDark.palette.Paper }}>
        <Box flexBasis={'100%'} p={2}>
          <Box flexBasis={'100%'} textAlign={'center'} variant='h6'>
            <Typography>Contact</Typography>
          </Box>
          <Box flexBasis={'100%'} variant='h6' mb={1}>
            <Typography variant='body2'>Email</Typography>
            <Typography variant='subtitle2'>Trueleo1@gmail.com</Typography>
          </Box>
          <Box flexBasis={'100%'} variant='h6'>
            <Typography variant='body2'>GitHub</Typography>
            <Typography variant='subtitle2'>github.com/trueleo1</Typography>
          </Box>{' '}
          <Box flexBasis={'100%'} variant='h6'>
            <Typography></Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ContactCard;

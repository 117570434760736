import { Container, Box } from '@mui/material';
import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import TitleBar from '#layout/TitleBar/TitleBar';
import FooterComponent from '#layout/FooterComponent/FooterComponent';

const LayoutComponent = () => {
  return (
    <Fragment>
      <Container disableGutters maxWidth={false}>
        <Box display='flex' flexWrap={'wrap'} flexBasis='100%'>
          <Box flexBasis={'100%'}>
            <TitleBar />
          </Box>
          <Box flexBasis={'100%'}>
            <Outlet />
          </Box>
          <Box flexBasis={'100%'}>
            <FooterComponent />
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
};

export default LayoutComponent;

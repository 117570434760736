import { createTheme } from '@mui/material/styles';

const themeLight = createTheme({
  "components": {
    "MuiCssBaseline": {
      "styleOverrides": {
        "body": {
          "marginBottom": "130px"
        }
      }
    }
  },
  "typography": {
    "fontFamily": "readingFont",
    "fontSize": 12
  },
  "palette": {
    "mode": "light",

    "primary": {
      "main": "#526E5D",
      "contrastText": "#fff",
    },
    "secondary": {
      "main": "#A6907C"
    },
    "background": {
      "default": "#D9D7D7"
    },
    "text": {
      "primary": "#000"
    }
  }
})

export default themeLight;
import { initializeApp } from "firebase/app";
import { getStorage  } from "firebase/storage";
// Your web app's Firebase configuration
const firebaseConfig = {
  //apiKey: "5c7b58cf-3ed7-4453-a249-597cae09f534",
  apiKey: "AIzaSyDQkRV9Rm2dkmJOvkHwC_v7Fpa1I4OoymY",
  authDomain: "pokeyhelper.firebaseapp.com",
  projectId: "pokeyhelper",
  storageBucket: "pokeyhelper.appspot.com",
  messagingSenderId: "35890232412",
  appId: "1:35890232412:web:23e3003c5c34ff73e37a99",
  measurementId: "G-JW0JGVKSJQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage and export it
export const storage = getStorage(app);

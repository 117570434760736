import { Box, Typography, Button, Link, Paper, Grow } from '@mui/material';
import { useState } from 'react';

import PokeLogo from '#resources/images/pokeball-logo-transparency-small.webp';

const PokeyHelperCard = () => {
  const [selection, setSelection] = useState('');

  const handleExpand = (selection) => {
    console.log(selection);
    setSelection(selection);
  };

  const handleCollapse = () => {
    setSelection('');
  };

  return (
    <Box
      flexBasis={'100%'}
      onMouseOver={() => {
        handleExpand('PokeyHelper');
      }}
      onMouseOut={handleCollapse}
      m={1}
    >
      <Paper m={3} elevation={8}>
        <Box p={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Box
            display='flex'
            flexWrap={'nowrap'}
            justifyContent={'center'}
            alignItems={'center'}
            flexBasis={'100%'}
          >
            <Box
              m={2}
              flexGrow={0}
              flexShrink={0}
              component='img'
              sx={{
                height: 50,
                width: 50
              }}
              alt='Pokey Helper Logo'
              src={PokeLogo}
            />
            <Box
              display='flex'
              flexWrap='wrap'
              flexBasis={200}
              flexShrink={1}
              flexGrow={1}
            >
              <Box flexBasis={'100%'}>
                <Typography variant='h6'>Pokey Helper</Typography>
              </Box>
              <Box flexBasis={'100%'}>
                <Typography variant='caption'>
                  A streamlined web app born from a passion for user-friendly solutions,
                  it offers quick access to game-related data without the clutter of ads
                  or extensive searches.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* {selection.includes('PokeyHelper') && (
          <Grow
            p={1}
            in={selection.includes('PokeyHelper')}
            style={{ transformOrigin: '0 0 0' }}
            {...(selection.includes('PokeyHelper') ? { timeout: 1000 } : {})}
          >
            <></>
          </Grow>
        )} */}
      </Paper>
    </Box>
  );
};

export default PokeyHelperCard;

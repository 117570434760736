import { Box, Paper, Typography } from '@mui/material';
import themeDark from '#resources/themes/themeDark';

const TDSClinicalList = [
  'Full stack web development using <b>Spring Framework, Vue , SQL Database, Jira, Concourse for Pipelines</b>',
  'Work with Project managers and UX to deliver survey based forms to customers',
  'Creating guidelines, wireframes, and tasks for sprint planning and development',
  'Track work in an <b>Agile</b> environment using <b>Jira</b>',
  'Developing <b>Spring Boot</b> Service projects for <b>Rest API</b> Resource',
  'Build and configure UI utilizing <b>Vue</b>, and custom built libraries of components',
  'Created the testing environment for the team and creating a standard of Quality Assurance to do functional, regression testing using front end <b>Playwright Testing Frame Work</b>'
];

const SRSList = [
  'Full stack web development using <b>Spring Framework, React, SQL Database, Jira, Concourse for Pipeline</b>',
  'Work with customers to determine requirements for project features, expanding capabilities, and bug resolution',
  'Creating guidelines, wireframes, and tasks for sprint planning and development',
  'Track work in an <b>Agile</b> environment using <b>Azure DevOps</b>',
  'Developing <b>Spring Boot</b> Service projects for Rest API Resource',
  'Build and configure UI utilizing <b>React</b>, <b>Material UI</b>, and custom built libraries of components',
  'Created the testing environment for the team and creating a standard of Quality Assurance to do functional, regression, and smoke testing of features using  <b>JUnit and Mockito</b>'
];

const ExperienceCard = () => {
  return (
    <Box>
      <Paper m={3} elevation={8} sx={{ background: themeDark.palette.Paper }}>
        <Box
          p={2}
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box m={1} flexBasis={'100%'}>
            <Typography textAlign={'center'} variant='h6'>
              Experience
            </Typography>
          </Box>
          <Box flexBasis={'100%'}>
            <Box>
              <Typography variant='subtitle1'>
                TDS Clinical, Orlando, Fl — Senior Software Engineer
              </Typography>
            </Box>
            <Box>
              <Typography variant='subtitle2'>APRIL 2021 - PRESENT</Typography>
            </Box>
            {TDSClinicalList &&
              TDSClinicalList.map((item, index) => {
                return (
                  <Box m={1} ml={2} key={index}>
                    <Typography
                      key={index}
                      variant='body2'
                      fontSize={'11px'}
                      dangerouslySetInnerHTML={{ __html: `&#x2022; ${item}` }}
                    ></Typography>
                  </Box>
                );
              })}
          </Box>
          <Box flexBasis={'100%'}>
            <Box>
              <Typography variant='subtitle1'>
                Savannah River Nuclear Site - Software Engineer
              </Typography>
            </Box>
            <Box>
              <Typography variant='subtitle2'>MAY 2017 - APRIL 2022</Typography>
            </Box>
            <Box>
              {SRSList &&
                SRSList.map((item, index) => {
                  return (
                    <Box m={1} ml={2} key={index}>
                      <Typography
                        key={index}
                        variant='body2'
                        fontSize={'11px'}
                        dangerouslySetInnerHTML={{ __html: `&#x2022; ${item}` }}
                      ></Typography>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ExperienceCard;

import { Box, Typography, Slide, Chip, Avatar } from '@mui/material';
import {
  buyMeCoffeeLogoTransparent,
  facebookLogo,
  instagramLogoTransparent,
  linkedInLogo
} from '#resources/images';

const NetworkPage = () => {
  const navigateToExternalSite = (url) => {
    window.location.href = url;
  };
  return (
    <Box m={1} display={'flex'} flexWrap={'wrap'} justifyContent='center'>
      <Box flexBasis={'100%'} m={2}>
        <Typography variant={'h5'} textAlign={'center'}>
          Network
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexBasis={600}
        flexShrink={1}
        flexWrap={'wrap'}
        justifyContent={'center'}
      >
        <Box flexBasis={'100%'} m={2}>
          <Typography variant={'h6'} textAlign={'center'}>
            Social
          </Typography>
        </Box>
        <Box m={1} flexBasis={75}>
          <Slide timeout={900} direction='up' in={true} mountOnEnter unmountOnExit>
            <Chip
              clickable
              variant='filled'
              size='medium'
              avatar={<Avatar alt='Natacha' src={buyMeCoffeeLogoTransparent} />}
              label='Buy Me Coffee'
              onClick={() =>
                navigateToExternalSite('https://www.buymeacoffee.com/drewology')
              }
            />
          </Slide>
        </Box>
        <Box m={1} flexBasis={100}>
          <Slide timeout={900} direction='up' in={true} mountOnEnter unmountOnExit>
            <Chip
              clickable
              variant='filled'
              size='medium'
              avatar={<Avatar alt='Natacha' src={instagramLogoTransparent} />}
              label='Instagram'
              onClick={() =>
                navigateToExternalSite('https://www.instagram.com/superior_drewology')
              }
            />
          </Slide>
        </Box>{' '}
        <Box m={1} flexBasis={100}>
          <Slide timeout={900} direction='up' in={true} mountOnEnter unmountOnExit>
            <Box>
              <Chip
                clickable
                variant='filled'
                size='medium'
                avatar={<Avatar alt='Natacha' src={facebookLogo} />}
                label='Facebook'
                onClick={() =>
                  navigateToExternalSite('https://www.facebook.com/andrewpk1')
                }
              />
            </Box>
          </Slide>
        </Box>
        <Box m={1} flexBasis={100}>
          <Slide timeout={900} direction='up' in={true} mountOnEnter unmountOnExit>
            <Box>
              <Chip
                clickable
                variant='filled'
                size='medium'
                avatar={<Avatar alt='Natacha' src={linkedInLogo} />}
                label='LinkedIn'
                onClick={() =>
                  navigateToExternalSite('https://www.facebook.com/andrewpk1')
                }
              />
            </Box>
          </Slide>
        </Box>
      </Box>
    </Box>
  );
};

export default NetworkPage;

import { Box, Paper, Typography } from '@mui/material';
import themeDark from '#resources/themes/themeDark';

const IntroCard = () => {
  return (
    <Box>
      <Paper elevation={8} sx={{ background: themeDark.palette.Paper }}>
        <Box p={2}>
          <Box flexBasis={'100%'}>
            <Typography color='secondary' variant='subtitle1'>
              Senior Software Engineer / Full Stack Developer / UX Design
            </Typography>
          </Box>
          <Box my={1} flexBasis={'100%'}>
            <Typography variant='body2'>
              Reliable and detail-oriented engineer looking to join a team with a focus of
              innovation, microservices, and UX design that takes an application from
              requirements gathering to production. Seeking a challenging and rewarding
              opportunity with a software engineering firm to use and grow my coding
              skills.
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default IntroCard;

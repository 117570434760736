import { Box, IconButton, Menu, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

import SocialMediaLinks from './components/SocialMediaLinks';
import NavigationBar from '#layout/NavigationBar/NavigationBar';
import { initialLogo } from '#resources/images';
import themeDark from '#resources/themes/themeDark';

const TitleBar = () => {
  const navigate = useNavigate();
  const desktop = useMediaQuery('(min-width:600px)');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNav = (nav) => {
    navigate(nav);
    handleClose();
  };

  return (
    <>
      <Box
        p={1}
        flexGrow={1}
        display={'flex'}
        flexWrap={'nowrap'}
        flexBasis={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          background: '#113A8C'
        }}
      >
        <Box>
          <Box
            m={1}
            display={'flex'}
            flexShrink={0}
            flexBasis={80}
            flexWrap={'nowrap'}
            justifyContent={'space-between'}
          >
            <Box
              component='img'
              sx={{
                height: 35,
                width: 95
              }}
              alt='The house from the offer.'
              src={initialLogo}
            />
            <Box p={1} flexBasis={'250px'} flexShrink={0} flexGrow={1}>
              {/* <NavigationBar /> */}
            </Box>
            <Box
              flexGrow={1}
              flexBasis={'100%'}
              display={'flex'}
              flexWrap={'wrap'}
              justifyContent='center'
            ></Box>
          </Box>
        </Box>

        {!desktop && (
          <>
            <Box>
              <IconButton onClick={handleClick}>
                <MenuIcon color='secondary' fontSize='large' />
              </IconButton>
            </Box>
          </>
        )}

        {desktop && (
          <>
            <Box>
              <NavigationBar />
            </Box>
            <Box>
              <SocialMediaLinks />
            </Box>
          </>
        )}
      </Box>

      <Box>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={() => handleNav('/')}>Home</MenuItem>
          <MenuItem onClick={() => handleNav('/Projects')}>Projects</MenuItem>
          <MenuItem onClick={() => handleNav('/Resume')}>Resume</MenuItem>
          <MenuItem onClick={() => handleNav('/Network')}>Network</MenuItem>

          <Box display={'flex'} justifyContent={'center'}>
            <SocialMediaLinks />
          </Box>
        </Menu>
      </Box>
    </>
  );
};

export default TitleBar;
